import React from "react"

const CustomLink = () => {
  const iframeStyles = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: "none",
  }

  return (
    <iframe
      src="https://deploy-preview-51--focused-mahavira-a02a88.netlify.app/" // Replace with your target webpage URL
      title="ETHSafari-2023"
      style={iframeStyles}
    />
  )
}

export default CustomLink
